import './App.css';

function App() {
  const videoURL = '/videos/basketball.mp4';
  return (
    <div>

      <video id="background-video" loop autoPlay muted >
        <source src={videoURL} type="video/mp4" />
        <source src={videoURL} type="video/ogg" />
      </video>

      <div className="gscontainer">
        <div className="gswelcome">

          <div className="gs-version">
            <span className="gs-version">- Version 0.1.0 -</span>
          </div>

          <div className="gs-title">
            <span className="gs-title-game">GAME</span><span className="gs-title-stats">STATS</span><span className="gs-title-io">.IO</span>
          </div>

          <div className="gs-social">
            <a href="https://twitter.com/gamestatsio" title="Twitter" rel="noreferrer" target="_blank"><i className="fa fa-twitter"></i></a> &nbsp;&nbsp;
            <a href="https://instagram.com/gamestatsio" title="Instagram" rel="noreferrer" target="_blank"><i className="fa fa-instagram"></i></a> &nbsp;&nbsp;
            {/* <a href="https://docs.gamestats.io" title="API Documentation"><i className="fa fa-terminal"></i></a> */}
          </div>

        </div>
      </div>

    </div>
  );
}

export default App;
